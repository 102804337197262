* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css');

.nav-btn {
    position: absolute;
    background-color: #E5C18A !important;
    z-index: 99;
    top: 2px;
    left: 5px;
    width: 50px !important;

}

/* .menu-bg { */
    /* background-color: hsl(25, 75%, 47%,30%); */

    /* background-image: url("../images/bg/menubg.png");
    background-size: 100vw 100vh; */


/* } */


.nav-btn svg {
    color: black;
    font-size: 25px;
    font-style: normal;
}

.btn-close-logo {
    border: 3px solid #231557;
    padding: 10px;
    border-radius: 100%;
    font-size: 15px;
    background-color: #FF1361;
    /* color: aliceblue !important; */
}

.offcanvas-header {
    position: relative;
    top: 7%;
    right: 4%;

}

.offcanvas-header button {
    border-radius: 50%;
    background-color: white;
    padding: 10%;
    font-size: 70%;
    z-index: 99;
    /* color: white; */

}

.offcanvas {
    width: 60vw !important;
    height: 100vh !important;
    background-image: url("../images/bg/menubg.png");
    margin-left: -2%;
    margin-top: -1%;

    /* background-image: url(../images/peacockwall2.png); */
    background-repeat: no-repeat;
    background-size: 95% 98%;
    /* background-color: rgb(179, 237, 217, 10%) !important; */
    background-position: center;
    /* height: fit-content; */
    background-color: transparent !important;
    /* opacity: 1; */
    /* filter: drop-shadow(2px 2px 3px black) !important; */
    /* border-radius: 0px 100px 20px 100px; */
}

.offcanvas * {
    filter: none;
}

/* .offcanvas-body img {
    height: 13vh;
} */
.menu-row
{
    height: 70vh;
}
/* .menu-col {
    border: #E5C18A 3px solid;
    height: 79vh;
} */
.offcanvas-body
{
    height: 80vh;
    width: 60vw;
    position: relative;
    left: 2%;
}
.offcanvas-body .menu-col
{
    height: 60vh;
    /* width: 95vw; */
  
    display: flex;
    justify-content: space-around;
    flex-direction: column;
}

@media screen and  (min-width: 480px)  {
    .menu-top img{
        height: 10vh !important;
      
    }
    .menu-mid img{
        height: 10vh !important;
      
    }
    .menu-bottom img{
        height: 14vh !important;
      
    }
    .contact-menu-col img{
        height: 10vh !important;
    
      
    }
    .contact-menu-col {
      display: flex;
      align-items: center;
      
    }
    .logo-menu img{
        height: 40vh !important;
      
    }
    .logo-menu {
        display: flex;
      align-items: center;
      
    }
    .offcanvas {
        width: 80vw !important;
      
    }
    .offcanvas-body .menu-col
    {
        height: 50vh;
    }

    .offcanvas-body{
        height: 90vh;
        width: 80vw;
    }
  

 
}
@media screen and  (min-width: 400px)  {
    .menu-top img{
        height: 5vh !important;
      
    }
   
    .menu-mid img{
        height: 7vh !important;
      
    }
    .menu-bottom img{
        height: 6vh !important;
     

      
    }
    .contact-menu-col img{
        height: 4vh !important;
    
      
    }
    .contact-menu-col {
      display: flex;
      align-items: center;
      
      
    }
    .contact-menu-row {
      display: flex;
      flex-direction: column;
      align-items: center;

      margin-top: 0vh !important;
      
      
    }
    .logo-menu img{
        height: 8vh !important;
      
    }
    .logo-menu {
        display: flex;
      align-items: center;
      
    }
    .offcanvas {
        width: 60vw !important;
      
    }
    .offcanvas-body .menu-col
    {
        height: 25vh;
    }

    .offcanvas-body{
        height: 90vh;
        width: 60vw;
    }

    .inner-contact-menu
    {
        display: flex;
     
      flex-direction: column;
    }
  

 
}
@media screen and  (min-width: 1400px)  {
    .menu-top img{
        height: 10vh !important;
      
    }
   
    .menu-mid img{
        height: 10vh !important;
      
    }
    .menu-bottom img{
        height: 12vh !important;
       

      
    }
    .contact-menu-col img{
        height: 10vh !important;
    
      
    }
    .contact-menu-col {
      display: flex;
      align-items: center;
      
      
    }
    .contact-menu-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      
      
    }
    .logo-menu img{
        height: 30vh !important;
      
    }
    .logo-menu {
        display: flex;
      align-items: center;
      
    }
    .offcanvas {
        width: 80vw !important;
      
    }
    .offcanvas-body .menu-col
    {
        height: 50vh;
    }

    .offcanvas-body{
        height: 90vh;
        width: 80vw;
    }

    .inner-contact-menu
    {
        display: flex;
     
      flex-direction: column;
    }
  

 
}



.contact-menu
{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    height: 100%;
}
.offcanvas-body div[class~="menu"]
{
    height: 100%;
    width: 100%;
    position: relative;
    /* left: 3%; */
   
    /* align-items: ; */
    
}
/* 
.offcanvas-header {
    position: relative !important;
    right: 5% !important;
    top: 4% !important;
} */

.dropdown-menu {
    border-radius: 0px 100px 20px 100px;
    background-color: transparent !important;
    border: none !important;
}

/* .offcanvas-body {
    width: 100% !important;
    background-color: white;
    font-size: medium;
    color: black;
    overflow-y: auto;
    /* width: 100vw; */
/* border-radius: 30px;
    background: transparent !important;
    height: fit-content;
    margin-top: -70px !important;
    padding: 150px !important; */
/* }  */

/* .canvasbody{
    height: 100%;
    width: 100%;
} */




.nav-logo {
    height: 100px;
    margin-top: -10px !important;
}

.menus {}


.menu-mid img
{
    height: 14vh;
}

.menu-top img
{
    height: 11vh;
}

.menu-bottom img
{
    height: 20vh;
}

.menu-item-1 {
    background: linear-gradient(111.4deg, rgb(55, 168, 192) -0.2%, rgb(103, 187, 125) 100.2%);
    /* background: linear-gradient(15deg, #13547a 0%, #80d0c7 100%); */
    box-shadow: 2px 2px 7px gray, -2px -2px 15px gray;
    /* opacity: 0.90; */
    color: rgb(188, 243, 226) !important;
}

.menu-item-2 {
    background: linear-gradient(-225deg, #231557 0%, #44107A 29%, #FF1361 67%, #FFF800 100%);
    /* background: linear-gradient(15deg, #13547a 0%, #80d0c7 100%); */
    box-shadow: 2px 2px 7px gray, -2px -2px 15px gray;
    /* opacity: 0.94; */
    color: rgb(245, 245, 177) !important;
}

.logo {
    margin-top: -70px !important;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;

    justify-content: center;
}

.Searchbar .form-control {
    height: 30px !;
    justify-content: center !important;

}

.modal-backdrop.show {
    opacity: 0 !important;
}