* {
    margin: 0;
    padding: 0;
}

.form-container {
    width: 50%;
    background-color: rgba(108, 90, 90, 0.209);
    padding: 2%;
    font-size: 18px;
}

.form-container button {
    padding: 1% 6%;
    font-size: 20px;
}

.form-container input {
    margin-bottom: 1%;
    padding: 5px 30px;
}

.page-container {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(187, 187, 187, 0.249);
}

.page-fetch {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    height: 100vh;
    padding: 1% 2%;
    background-color: rgba(187, 187, 187, 0.249);
}

.table.form-container tr
{
   text-align: center;
   
    
}
.table.form-container
{
    border: 1px solid black;
    
}
.form-container thead th
{
    background-color: black;
    color: white;
    text-align: center;

}
