

.videobox {
    position: relative;
    height: 99%;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    object-fit: cover;
}

.videodetailsBox{
    position: relative;
    height: 100%;
    width: 100%;
}

.videobox object{
    width: 100%;
  height: 100%;
}