.special-date {
    background-color: white;
    border-radius: 5px;
}


.special-event {
    display: none;
    position: relative;
    padding: 2px 2px;
    box-shadow: 2px 2px 3px gray;
    border-radius: 10px;


}


.react-calendar__month-view__weekdays__weekday abbr {
    border-bottom: none !important;
    cursor: inherit !important;
    text-decoration: none !important;

}

.react-calendar_month-view_weekdays {
    background-color:#E5C18A;
}

.special-date:hover .special-event {
    position: absolute;
    display: block;
    background-color: red;
    color: white;
}




/* ///////////////// */


.react-calendar {
    border: none;
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    background-color: #E5C18A;
    /* margin: 10px; Add some margin for spacing */
    padding:5px 10px;
}

button.react-calendar__tile--now
{
    background-color: brown !important;
}


.react-calendar__navigation {
    padding: 5px;
    background-color: red;
    border-radius: 8px 8px 0 0;
    display: flex;
    justify-content: space-between;
}

.react-calendar__navigation button {
    color: #fff;
    background: none;
    border: none;
    cursor: pointer;
  
}


/* .react-calendar__month-view__weekdays {
    text-align: center;
    text-decoration: none !important;
    color: #4DCAFA;
} */

.react-calendar__month-view__weekdays__weekday {
    /* padding: 15px; */
  
    color: red;

}



.react-calendar__tile--active {
  
    border-radius: 6px;
    font-weight: bold;
    color: white;
}


