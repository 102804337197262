
 .uplodedphoto {
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    background-color: black;
}

.uplodedphoto2 {
    width: 100%;
    /* height: 100%; */
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(234, 224, 224, 0.1);
    cursor: pointer;
    /* border: 2px solid white; */

}

.uplodedImage img {
    width: 100%;
    height: 200px;
    border-radius: 10px;
}

.uplodedphoto2 img {
    border-radius: 10px;
    max-width: 100%;
    height: 150px;
}

.image-content p{
    /* width: 100%; */
    color: white !important;
    text-align: start;
    font-size: 15px;
    /* border: 2px solid white; */
    margin: 20px ;

}