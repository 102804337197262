.videoshow{
    width: 100%;
    height: 100%; 
}
.containervoideos {
    width: auto ;
    height: 714px;
    background-color: black;
    /* border-radius: 11px; */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    /* margin-top: 10px ; */
    overflow: auto;

}

.containerslidvoideos {
    overflow: auto;
    /* width: 50% !important; */
    height: 714px;
    margin: 0 auto;
    background-color: black;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-top: 20px;
    scrollbar-width: none !important; 


}