
.Donate{
    height: 100%;
    width: 100%;
 
    /* border: 1px solid black; */
    position:relative;
    text-align: center; 

}
.QRcodebox{
    height: auto;
    width: auto;
    /* border: 2px solid black; */
    display: flex;
    justify-content: center;
 
}

.slider 
{
    border:1px solid yellow;
    outline:1px solid yellow;
    outline-offset:7px;
}

.slider-donate img
{
    height: 80vh;
}

.QRcode{

    height: 400px;
    width: 400px;
 
   
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center ;
    
}

.QRcode img {
    height: 80%;
    /* width: 100%; */
}


