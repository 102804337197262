.gallaryshow {
    
    width: 100%;
    height: 100%;    
}

.category-list-item
{
    cursor: pointer;
}
.category-list-item:hover
{
   color: rgb(250, 248, 248) ;
   background-color: rgb(103, 185, 185) ;
}

.ContainerGallary {
    width: auto  ;
    height: 930px;
    background-color: black;
    /* border-radius: 11px; */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    /* margin-top: 10px ; */
    overflow: hidden;

}

.ContainerSideGallary {
    overflow: auto;
    /* width: 50% !important; */
    height: 930px;
    margin: 0 auto;
    background-color: black;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    margin-top: 20px;
    scrollbar-width: none !important; 


}