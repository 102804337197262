.navtext {
   padding: 2% 3%;
    /* border: 2px solid black; */
    position: relative;
    left: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(133, 133, 133, 1);
    border-radius: 20px;
    color: white !important;
    font-size: 20px !important;
    font-family: 'Times New Roman', serif;
}

.card-col
{
   display: flex;
   justify-content: center; 
}
.top-card-col 
{
   display: flex;
   justify-content: center; 
  
}
.top-card-col div
{
   display: flex;
   justify-content: center; 
   background-color: rgb(8, 49, 49);
   color: #f8f9fa;
   font-size: 22px;
   text-decoration: underline;
}

.card-container
{
    padding: 10%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 100%;
    height: 100%;
}

.logoutbtn {
    width: 130px;
    height: 50px;
    font-family: 'Times New Roman', serif !important;
    font-size: 25px !important;

}

.sidecontainer {
    height: 100%;
    width: 18vw;
    background-color: rgba(225, 219, 219, 1);
    padding: 2% 1%;
}

.accordion-item {
    margin-bottom: 5%;

}
 

.accordion-body {
    display: flex;
    flex-direction: column;
    align-content: center;
   
    
    
}
.accordion-body button {
   margin: 10px 0px;
   
    
    
}

.accordion-header button {
    font-size: 50%;
    padding: 6% 10%;
    font-weight: bold;
  
   
}

.dashboard-container {
    display: flex;
    height: calc(100vh - 70px);
    width: 100%;
}

.content {
    flex-grow: 1;
    background-color: #f8f9fa;
    overflow-y: auto;
}