
.slider 
{
    border:1px solid yellow;
    outline:1px solid yellow;
    outline-offset:7px;
}
.header {
  
    padding: 20px 0;
    text-align: center;
    color: white;
}
.header .img {
    width: 100%;
    max-width: 600px;
    border-radius: 15px;
}
.header h1 {
    font-size: 2.5rem;
    margin-top: 20px;
}
.header .btn {
    background-color: #f76c6c;
    border: none;
    color: white;
    padding: 10px 20px;
    font-size: 1rem;
    border-radius: 5px;
}
.content {
    padding: 40px 0;
    text-align: center;
}
.content .card {
    border: none;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 20px;
}
.content .card h3 {
    font-size: 1.5rem;
    margin-bottom: 20px;
}
.content .card p {
    font-size: 1rem;
    color: #666;
}
.content .card ul {
    list-style: none;
    padding: 0;
}
.content .card ul li {
    font-size: 1rem;
    color: #666;
    margin-bottom: 10px;
}
.content .card ul li span {
    font-weight: bold;
}
.footer {
    text-align: center;
    padding: 20px 0;
    color: #f76c6c;
}