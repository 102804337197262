    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Poppins', sans-serif;
    }

    @import url('https://fonts.googleapis.com/css2?family=Italianno&display=swap');
    @import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css');
    @import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');

    /* //////////////////Slider /////////////////// */
    @import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap');

    @import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&family=Great+Vibes&display=swap');
    @import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap');

    .sliders {
        /* background-image: radial-gradient(circle at 20% 50%, black, #847c71); */
        background-image: url('../images/bgmi111.jpg');
        /* background-color:black; */
        background-size: cover;
        object-fit: cover;
        /* width: 100vw; */

        overflow: hidden;
        position: relative;
    }


    
    .playfair-display {
      font-family: "Playfair Display", serif;
      font-optical-sizing: auto;
      font-weight: 700;
      font-style: normal;
    }

    .BhgwanImg {
        height: 100%;
        width: 100%;
        /* border: 3px solid red; */
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .katha-img-btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

    }

    .katha-img-btn img {
        margin: 10px 0;
        height: 9em;
        box-shadow: 0 0 0 black;
        transition: height 0.2s, margin 0.2s, box-shadow 0.2s;
    }

    .katha-img-btn img:hover {
        margin: 10px 10px 10px 0;
        height: 9.1em;
        box-shadow: 3px 3px 3px black, -3px -3px 3px black;

    }

    .BhgwanImg img {
        height: 80%;
        position: absolute;
    }

    .img1 {
        opacity: 0;
        animation: fadeInOut 3s infinite;
        animation-delay: 1s;
    }

    .img2 {

        opacity: 0;
        animation: fadeInOut 6s infinite;
        animation-delay: 3s;
    }

    .img3 {

        opacity: 0;
        animation: fadeInOut 9s infinite;
        animation-delay: 10s;

    }


    @keyframes fadeInOut {

        0%,
        100% {
            opacity: 0;
        }

        50% {
            opacity: 1;
        }
    }

    .sometext {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

    }

    .sometext p {
        font-family: "Italianno", cursive;
        font-weight: 800;
        font-style: normal;
        font-size: 3.5em;
        text-align: center;
        color: #E5C18A;
        text-decoration: underline;
    }

    .bookingcards {
        height: 100%;
        width: 100%;
        /* border: 2px solid black; */
        display: flex;
        flex-direction: column;
        justify-content: end;
        align-items: end;
        margin-top: 12%;
    }

    .row-full {
        height: 100vh;
    }


    .bookingcards1 {
        position: static;
        height: 200px;
        width: 300px;
        margin: 0 0 10px;
        /* margin-left: 50%; */
        /* background-color: #462620;
        border: 10px solid #E5C18A; */
        background-image: url('../images/bgtrangle11.jpg');
        background-size: cover;
        object-fit: cover;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .card1 {
        text-align: center;
        font-family: "Italianno", cursive;
        font-weight: 600;
        font-size: 50px;
        font-style: normal;
        color: #E5C18A;
        text-decoration: underline;
        margin-top: 20px;
    }


    .bookingcards1 button {
        font-family: "Italianno", cursive;
        font-weight: 400;
        font-style: normal;
        font-size: 40px;
        color: black;
        background-color: #E5C18A;
        cursor: pointer;
        border: none;
        font-weight: bold;
    }

    .book-button {
        border-radius: 10px;
        width: 130px;
        height: 50px;
        box-shadow: 5px 10px black;
    }

    .bookingcards2 {
        position: static;
        margin: 0 0 10px;
        height: 210px;
        width: 400px;
        /* background-color: #E5C18A;
        border: 10px solid #462620; */
        background-image: url('../images/bgimgRectangle.jpg');
        background-size: cover;
        object-fit: cover;
        /* margin-left: 34%; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .card2 {
        text-align: center;
        font-family: "Italianno", cursive;
        font-weight: 600;
        font-size: 50px;
        font-style: normal;
        color: #994B1B;
        text-decoration: underline;
        margin-top: 30px;
        font-weight: bold;

    }

    .bookingcards2 button {
        font-family: "Italianno", cursive;
        font-weight: 400;
        font-style: normal;
        font-size: 40px;
        color: #E1AC5B;
        background-color: #462620;
        cursor: pointer;
        border: none;
        font-weight: bold;
    }

    .bookingcards3 {
        position: static;
        height: 200px;
        width: 300px;
        background-image: url('../images/bgRectangle42.jpg');
        background-size: cover;
        object-fit: cover;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }

    .card3 {
        text-align: center;
        font-family: "Italianno", cursive;
        font-weight: 600;
        font-size: 50px;
        font-style: normal;
        color: #E5C18A;
        text-decoration: underline;
        margin-top: 30px;
        font-weight: bold;

    }

    .bookingcards3 button {
        font-family: "Italianno", cursive;
        font-weight: 400;
        font-style: normal;
        font-size: 40px;
        color: #462620;
        background-color: #E5C18A;
        cursor: pointer;
        border: none;
        font-weight: bold;
    }

    /* //////////////////////////////////////////// */



    .sociallinks {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        /* margin: 10px; */
        /* margin-left: 790px; */
        margin-top: 10px;
    }

    .sociallinks a {
        display: block;
        width: 40px;
        height: 40px;
        margin-right: 10px;
    }

    .sociallinks img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 30%;
    }

    @media (min-width: 768px) {
     
    }
    @media (min-width: 300px) {
    
    }
 

/* Default styles for mobile devices */


/* Small devices (e.g., phones in portrait mode, less than 600px) */
@media only screen and (max-width: 600px) {

    .Headingh1
    {
        font-size: 60px !important;
    }

    body{
        width:97vw !important;
    }

    .maplocation > .row{
        width:100vw !important;
    }

    .offcanvas-backdrop
    {
        width: 95vw !important;
    }

    .row {
        width: 100% !important;
    }

   
    .gallaryimagebtn img
    {
        width:80vw !important;
        
    }

    html
    {
        width: 100vw !important;
    }
    .bookingbtns {
        flex-direction: row;
        justify-content: space-between;
      

    }

  

    .sociallinks {
        flex-direction: row;
        margin-top: 0;
    }

    .sociallinks a {
        margin-bottom: 0;
    }
    .gallaryimagebtn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

  

    .katha-img-btn
    {
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
    }
    .bookingbtns {
        margin-top: 60px;

        /* background-image: url(../images/bgimage4.png); */
        background-size: cover;
        object-fit: cover;
    }

    .gallaryimagebtn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .map img {
        height: 18em;
        width: 18em;
        outline: 2px solid #E5C18A;
        outline-offset: 25px;
        margin-top: 20px;
    }

    .menu-top img,.menu-mid img,.menu-bottom img,.contact-menu-row img
    {
        height: 6vh !important;
    }

    .logo-menu img{
            height: 16vh !important;
    }

    .contact-menu-row,.inner-contact-menu
    {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    /* Add your styles for small devices */
}

/* Medium devices (e.g., tablets in portrait mode, 600px to 768px) */
@media only screen and (min-width: 600px) and (max-width: 768px) {
   
    /* Add your styles for medium devices */
}

/* Large devices (e.g., tablets in landscape mode, 768px to 992px) */
@media only screen and (min-width: 768px) and (max-width: 992px) {
    .bookingbtns {
        flex-direction: row;
        justify-content: space-between;
      

    }
    .sociallinks {
        flex-direction: row;
        margin-top: 0;
    }
    .sociallinks a {
        margin-bottom: 0;
    }
    .gallaryimagebtn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }     
    .katha-img-btn
    {
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
    }
    .bookingbtns {
        margin-top: 60px;
        /* background-image: url(../images/bgimage4.png); */
        background-size: cover;
        object-fit: cover;
    }

    .gallaryimagebtn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .map img {
        height: 18em;
        width: 18em;
        outline: 2px solid #E5C18A;
        outline-offset: 25px;
        margin-top: 20px;
    }
    /* Add your styles for large devices */
}

/* Extra large devices (e.g., laptops/desktops, 992px to 1200px) */
@media only screen and (min-width: 992px) and (max-width: 1200px) {
 
    /* Add your styles for extra-large devices */
}

/* Extra extra large devices (e.g., desktops, more than 1200px) */
@media only screen and (min-width: 1200px) {
   
    /* Add your styles for extra-extra-large devices */
}




    @keyframes buttonHover {
        0% {

            background-color: #2ecc71;
        }

    }

    .animated-btn:hover {
        box-shadow: 0 0 5px lightcoral;
    }

    /* //////////////Some Dates with Details////////////////////// */
    .CalenderDetails {

        height: 100%;
        margin-top: -50px;

    }

    .Calendarimg {
        width: 100%;
        height: 100%;
        margin-top: 50px;
        background-image: url('../images/bgimgFrame 8.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        object-fit: cover;

    }

    .bg-right {
        background-image: url('../images/bgmi111.jpg');
        border-radius: 0;
    }

    .bg-left {
        background-image: url('../images/bgimgFrame 8.jpg');
    }

    .Calendarcontainer {
        width: 100%;
        height: 100%;
        background-size: cover;
    }


    .innercal {
        padding-bottom: 70px;

    }

    .sno-booking {

        border-radius: 20%;
        font-weight: 700;
        padding: 3px 1%;
        font-size: 20px;

    }

    /* width */
    ::-webkit-scrollbar {
        width: 1px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: rgba(148, 20, 20, 0);

    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: rgb(229, 193, 138, 0);
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: rgb(229, 193, 138, 4)
    }




    .dates-list-box {
      
        padding: 0;
        margin: 0;
        margin-bottom: 30px;
        overflow-y: auto;
        display: flex;
        justify-content: center;
        align-items: center;

    }

    .dates-list-box:hover::-webkit-scrollbar-thumb {
        background: rgb(229, 193, 138, 0.7);

    }

    .dates-list-box:hover::-webkit-scrollbar-track {
        background: rgba(148, 20, 20, 0.7);

    }

    .dates-list {
        /* background: transparent; */
        /* background-image: url("../images/bg/bgRectangle.png"); */
        /* height: 500px; */

    }

    .room-booking {
        background: transparent;
        /* background-image: url("../images/bg/bgRectangle.png"); */
        /* height: 500px; */
        /* font-family: "Italianno", cursive; */
        margin: 0 30px;
    }

    .room-booking li {
        background: transparent;

        padding-bottom: 0;
        padding-top: 20px;
        background-image: url("../images/bg/bgRectangle.png");
        /* font-family: "Italianno", "cursive" !important; */
        border: "1px solid";
        border-color: rgb(229, 193, 138, 0.1);
    }

    .dates-list li {
        background: transparent;
        margin: 0 2%;
        padding-bottom: 0;
        padding-top: 20px;
        background-image: url("../images/bg/bgRectangle.png");

    }


    .dates-list * {
        background: transparent;
        color: #E1AC5B;
        font-family: "Italianno", "cursive";
        font-size: large;
        /* font-weight: bold; */
        letter-spacing: 4px;
    }

    .calenderBox {
        width: 100%;
        height: 50%;
        object-fit: cover;
        padding: 0 1%;
        /* border: 3px solid black;/ */
    }

    .detailsBox {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .detailsBox img {
        margin: 0;
        height: 4em;
        margin: 10px 0;
        transition: height 0.2s, margin 0.2s;
    }

    .detailsBox img:hover {
        margin: 5px 5px 5px 0;
        height: 4.3em;
    }


    .Headingh1 {
        font-family: "Italianno", cursive;
        font-weight: 400;
        font-style: normal;
        font-size: 90px;
        color: #ECB45F;
        text-align: center;
        padding-bottom: 10px;
        font: bold;
    }



    p {
        color: black;
        text-align: start;
        font-size: 20px;

    }

    .bookingblock1 {
        position: static;
        height: 120px;
        width: 520px;
        margin: 0 0 70px;
        background-image: url('../images/bookingblock1.png');
        background-size: cover;
        object-fit: cover;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .bookingblockcrd1 {
        text-align: center;
        font-family: "Italianno", cursive;
        font-weight: 600;
        font-size: 40px;
        font-style: normal;
        color: #E5C18A;
        text-decoration: underline;
    }

    .bookingblock1 a {
        text-align: center;
        /* font-family: "Italianno", cursive; */
        font-weight: 300;
        font-size: 40px;
        text-decoration: underline;
        color: red;
        cursor: pointer;
        border: none;
        text-align: center;
        margin-left: 80px;
        margin-bottom: 8px;

    }

    .bookingblock2 {
        position: static;
        height: 120px;
        width: 520px;
        margin: 0 0 70px;
        background-image: url('../images/Bookingblocks2.png');
        background-size: cover;
        object-fit: cover;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* margin-top: 10px; */
    }

    .bookingblockcrd2 {
        text-align: center;
        font-family: "Italianno", cursive;
        font-weight: 600;
        font-size: 40px;
        font-style: normal;
        color: #E5C18A;
        text-decoration: underline;
        /* margin:; */
    }

    .bookingblock2 a {
        text-align: center;
        /* font-family: "Italianno", cursive; */
        font-weight: 300;
        font-size: 40px;
        text-decoration: underline;
        color: red;
        cursor: pointer;
        border: none;
        text-align: center;
        /* margin-bottom: 10px; */
        margin-bottom: 8px;

    }

    .bookingblock3 {
        position: static;
        height: 120px;
        width: 520px;
        margin: 0 0 10px;
        background-image: url('../images/Bookingblocks2.png');
        background-size: cover;
        object-fit: cover;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        /* margin-top: 10px; */
    }

    .bookingblockcrd3 {
        text-align: center;
        font-family: "Italianno", cursive;
        font-weight: 600;
        font-size: 40px;
        font-style: normal;
        color: #E5C18A;
        text-decoration: underline;
        /* margin:; */
    }

    .bookingblock3 a {
        text-align: center;
        /* font-family: "Italianno", cursive; */
        font-weight: 300;
        font-size: 40px;
        text-decoration: underline;
        color: red;
        cursor: pointer;
        border: none;
        text-align: center;
        margin-bottom: 8px;

    }

    /* ///////////////Gallary Popular Searches////////////////// */

    .gallarycontent {
        width: 100%;
        height: 100%;
        background-image: url('../images/bgmi111.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        padding-top: 50px;
        padding-bottom: 10px;
    }

    .gallary {
        width: 100%;
        height: 100%;
        /* border: 2px solid black; */

    }

    .GallaryHeading {
        text-align: center;
        font-family: "Italianno", cursive;
        font-weight: 500;
        font-size: 80px;
        font-style: normal;
        color: #E5C18A;
        text-align: center;
    }

    .imagegallary {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        padding: 20px;
        margin-bottom: 70px;
    }

    .imagegallarybox1 {
        flex: 1 1 50%;
        max-width: 50%;
        padding: 10px;
        /* border: 1px solid yellow; */
        display: flex;
        align-items: center;
        justify-content: center;
    }

    img[alt*="img"] {
        position: absolute;
        height: 15em;
        width: 15em;
        z-index: 1;
        /* transition: z-index 2s; */
        border: 5px solid #E5C18A;
        border-radius: 20px;

    }

    .gallaryimage {
        display: flex;
       justify-content: center;

    }

    .gallaryimagebox {
       display: flex;
       justify-content: center;
    } 
    .gallaryimagebox .carousel-item{
       display: flex;
       justify-content: center;
    } 

    .gallaryimage img[alt="img1"] {
        left: 100px;
        top: 80px;


    }

    .gallaryimage img[alt="img2"] {
        top: -20px;
        left: 40px
    }

    .gallaryimage img[alt="img3"] {
        left: -20px;
        top: 40px;
    }

    img[alt*="img"]:hover {
        z-index: 999;
    }

    .gallaryimagebtn {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
    }

    .gallaryimagebtn img {
        height: 3.5em;
        margin: 10px;
        
    }

    /* .gallaryimage1 {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        /* border: 1px solid red; */
    /* } */

    /* .gallarypic1 {
        height: 40%;
        width: 40%;
        position: relative;
    }

    .gallarypic1,.gallarypic2,.gallarypic3 img {
        position: relative;
       
        height: 70%;
        width: 70%;
      

    }

    .gallarypic2 {
        height: 40%;
        width: 40%;
        position: absolute;
    }

   

    .gallarypic3 {
        height: 30%;
       
        position: absolute;
    } */




    .image-hover:hover {
        z-index: 1;
        transform: scale(1.1);
        filter: brightness(100%);
    }


    .gallarypic1 img,
    .gallarypic2 img,
    .gallarypic3 img {
        transition: transform 0.5s ease, filter 0.5s ease, ;
    }



    .imagegallarybox2 {
        flex: 1 1 50%;
        max-width: 50%;
        padding: 10px;

    }

    .gallaryimage2 {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-end;
        height: 100%;
        width: 100%;
    }

    .imagegallarycard1 {
        position: static;
        height: 117px;
        width: 649px;
        margin: 0 0 40px;
        background-image: url('../images/imagegallarycard1.png');
        background-size: cover;
        object-fit: cover;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .imagegallarycard2 {
        position: static;
        height: 117px;
        width: 712px;
        margin: 0 0 40px;
        background-image: url('../images/imagegallarycard2.png');
        background-size: cover;
        object-fit: cover;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .imagegallarycard3 {
        position: static;
        height: 117px;
        width: 649px;
        margin: 0 0 40px;
        background-image: url('../images/imagegallarycard3.png');
        background-size: cover;
        object-fit: cover;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }







    /* ////////YouTube Popular Searches//////////// */
    .outerbox {
        width: 100%;
        height: 100%;
        /* border: 2px solid black; */
        background-image: url('../images/bgimgFrame 8.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        object-fit: cover;
        padding-top: 50px;
        padding-bottom: 10px;
    }

    .VideosHeading {
        text-align: center;
        font-family: "Italianno", cursive;
        font-weight: 500;
        font-size: 80px;
        font-style: normal;
        color: #E5C18A;
        text-align: center;
    }


    .innerbox {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        padding: 20px;
        margin-bottom: 70px;
    }

    .videogallrybox1 {
        flex: 1 1 50%;
        max-width: 50%;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .videogallry1 {
        height: 18em;

    }

    .videogallry2 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

    }

    .videogallry2 img {
        height: 3.5em;
        margin: 10px;
    }

    .videogallrycard1 {
        position: static;
        height: 140px;
        width: 649px;
        margin: 0 0 40px;
        background-image: url('../images/videocard1.png');
        background-size: cover;
        object-fit: cover;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .videogallrycard2 {
        position: static;
        height: 117px;
        width: 710px;
        margin: 0 0 40px;
        background-image: url('../images/videocard2.png');
        background-size: cover;
        object-fit: cover;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .videogallrycard3 {
        position: static;
        height: 117px;
        width: 649px;
        margin: 0 0 40px;
        background-image: url('../images/videocard3.png');
        background-size: cover;
        object-fit: cover;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }












    /* ////////////////Vrindavan Free Stay Booking////////////////////// */
    .vrindavnFreeStayBooking {
        /* background-image: url(../images/bgimg/desktop-wallpaper-black-dark.jpg); */
        background-image: url('../images/bgmi111.jpg');

        background-repeat: no-repeat;
        background-size: cover;
        object-fit: cover;
    }



    /* ////////////////////////////////// */

    .location {
        /* background-image: url(../images/bgimg/Free-blue-pink-background-2776413775.jpg); */

        background-repeat: no-repeat;
        background-size: cover;
        object-fit: cover;
        height: 100%;
        width: 100%;
    }

    .maplocation {
        height: 100%;
        width: 100%;
    }

    .map {

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        /* border: 3px solid black;
        background-image: url('../images/map.png'); */

    }

    .about {

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        /* border: 3px solid black;
        background-image: url('../images/map.png'); */

    }

    .map img {
        height: 30em;
        outline: 2px solid #E5C18A;
        outline-offset: 25px;
        margin-top: 20px;
    }

    .about img {
        height: 15em;
        outline: 2px solid #E5C18A;
        outline-offset: 25px;
        margin-top: 20px;
    }

    .maptext {
        font-family: "Italianno", cursive;
        font-weight: 400;
        font-style: normal;
        font-size: 35px;
        color: #E5C18A;
        text-align: center;
        font: bold;
        text-decoration: underline
    }

    .mapinfo {
        height: 100%;
        width: 100%;
        /* border: 2px solid #E5C18A; */
        display: flex;
        flex-direction: column;
        /* justify-content: center; */
        align-items: center;
        outline: 2px solid #E5C18A;
        outline-offset: 5px;
        background-image: url("../images/bg/bgRectangle.png");

    }

 

    .locationtext {

        border: 3px solid #E5C18A;
        border-radius: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 30px;
        font-size: 26px;
        padding: 10px;
        text-align: center;
        font-family: "Italianno", cursive;
        color: #E5C18A;

    }

    .main-scan {
        color: #941414;
        font-size: 30px;
        font-family: "Italianno", cursive;
        padding: 0 20px;
        margin: 0;
    }

    .main-name {
        color: #941414;
        font-size: 30px;
        font-family: "Italianno", cursive;
    }

    /* .locationtext h1 {
        font-family: "Italianno", cursive;
        font-weight: 800;
        font-style: normal;
        font-size: 50px;
        color: #E5C18A;
        text-align: center;
        padding-bottom: 10px;
        font: bold;
        text-decoration: underline;
    } */

    .locationboxQR {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .locationboxQR img {
        height: 15em;
        outline: 2px solid #E5C18A;
        outline-offset: 10px;
    }





    .locationbox {}

    /* 
    .socialmeadialink {
        width: 100%;
        height: 100px;
        margin-top: 40px;
        display: flex;
        justify-content: center;
    }

    .logo img {
        max-width: 28%;
        height: auto;
    }

    .list .logo {
        text-align: center;
        padding-top: 80px;
    }

    .info {
        font-size: 20px;
        font-weight: 500;
        text-align: center;
        cursor: pointer;
        color: #ed0a0a;
        opacity: 0.8;
        margin-top: 30px;
    }

    .info p {
        font-size: 17px;
        margin: 0;
        text-align: center;
        color: white;
        font-weight: 600;

    } */



    /* //////////////////////Profile///////////////////////// */

    .profilelinks {
        width: 100%;
       
        background-image: url('../images/bgmi111.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        padding-bottom: 40px;

    }

    .profile-links {
        border: 3px solid #E1AC5B;
        box-sizing: content-box;
       

    }

    .profile-links img {
        height: 90%;

    }

    .profile-links * {

        font-family: 'Italianno', 'cursive' !important;
    }

    .profile-links .img-col {
     
       height: 50%;
       
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .profile-links .contact-col {
        padding: 5px;
        height: 100%;
        font-size: 150%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        color: white;
    }


    .contactashrm {
        font-family: "Italianno", cursive;
        font-weight: 800;
        font-style: normal;
        font-size: 2.5rem;
        color: #E5C18A;
        text-align: center;
        padding-bottom: 10px;
        font: bold;
        text-decoration: underline;
    }


    .contactnumber {
        font-family: "Italianno", cursive;
        font-weight: 800;
        font-style: normal;
        font-size: 50px;
        color: #E5C18A;
        text-align: center;
        padding-bottom: 10px;
        font: bold;
        text-decoration: underline;
    }
.links-col
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
    .links {
        font-family: "Italianno", cursive;
        font-weight: 400;
        font-style: normal;
        font-size: 20px;
        color: #E5C18A;
        text-align: center;
        padding-bottom: 10px;
        position: relative;
       
    }


    .socialmedialink {
       
    }

    .socialmedialink a {
        text-decoration: none;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: start;

    }

    .socialmedialink img {
        width: 3rem;
        height: 10%;
        object-fit: cover;
        border-radius: 0.9rem;
        margin-bottom: 20px;

    }

    .socialmedialink h1 {
        font-family: "Italianno", cursive;
        font-weight: 400;
        font-style: normal;
        font-size: 15px;
        color: #F91D26;
        text-align: start;
        padding-bottom: 10px;
        font: bold;
    }


.bio-col li
{
background-color: transparent;
color: white;
}

.bio-col *
{
    font-family: "Italianno", cursive;
}


    /* /////////////////////////// */
    .end {
        height: 50px;
        width: 100%;
        /* background-color: purple; */
        text-align: center;
        color: white;
        /* background-image: url('../images/footerimg.jpg'); */
        background-repeat: no-repeat;
        background-size: cover;
        object-fit: cover;
    }